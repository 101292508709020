.top {
  /* background: #13215e; */
  padding: 0.8rem 0rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
}
.LiveChatMessageArea {
  width: 370px;
  border-bottom-left-radius: 20px;
  display: none;
  border-bottom-right-radius: 20px;
}
.LabelWrapper {
  min-height: 30px;
  min-width: 30px;
  cursor: pointer;
  border-radius: 50%;
  display: block;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  justify-content: center;
}
.presentation {
  /* margin-left: 0rem; */
}
.heading_top {
  font-size: 19px;
}
.para_top {
  font-size: 12px;
}
.CloseIcon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  font-weight: bold;
  font-size: 18px;
}

.closeIconIntro {
  position: absolute;
  /* top: 8vh; */
  cursor: pointer;
  right: 45px;
  font-size: 18px;
}

.operator {
  font-size: 14px;
  width: 50px;
  padding-left: 10px;
}

.body {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  position: relative;
}

.body_600 {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin: 0 12px;
  margin-top: 40px;
}

.bodyContent {
  height: 450px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 1.5rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-bottom: 120px;
}
.form {
  background: #fff;
  border-top: 1px solid #ddd;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: fixed;
  width: 370px;
  bottom: 75px;
}
.inputWrapper {
  height: 70px;
  display: flex;
  align-items: center;
}
.IconWrapper {
  min-width: 30px;
  font-size: 20px;
  cursor: pointer;
  margin-right: 5px;
  color: #ddd;
}
.Smile {
  margin-right: 5px;
  font-size: 20px;
  color: #777;
}
.input {
  height: 100%;
  width: 100%;
  flex: 1;
  padding: 0rem 1rem;
  background: transparent;
  border: none;
  outline: none;
  font-family: "DM Sans", sans-serif;
  padding-top: 1rem;
}
.input::-webkit-resizer {
  display: none;
}

.top_600 {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  /* position: fixed; */
  width: 100%;
  z-index: 99;
  top: 0;
  border-radius: 0px;
  padding-top: 8vh;
  font-size: 20px;
}
.top_600 h4 {
  font-size: 26px;
}
.form_600 {
  width: 100%;
  bottom: 0px;
}
.LiveChatMessageArea_600 {
  height: 100vh;
  width: 100%;
  bottom: 0 !important;
  left: 0;
  position: fixed;
  border-radius: 0px;
  opacity: 1 !important;
  transform: translateY(0px) !important;
  background: #fff;
}

.bodyContent_600 {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  /* padding-top: 110px; */
  height: 80vh;
  padding-bottom: 70px;
}
.iconsContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;
  /* margin-top: 1rem; */
  margin-bottom: 0.5rem;
}
