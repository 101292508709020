.HomeCard {
  box-shadow: 0 5px 15px -5px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
}
.Top {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LongText {
  color: #fff;
  font-size: 9rem;
  text-align: center;
}
.image {
  width: 100%;
  height: 100%;
}
.content {
  padding: 1.5rem;
  background: #fff;
  min-height: 260px;
  flex: 1;
}
.head {
  color: #333;
  font-size: 25px;
}
.cardUL {
  margin-top: 2rem;
}
.list {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.list_para {
  margin-left: 20px;
}
.para {
  line-height: 1.5rem;
  margin-top: 20px;
  color: #333;
  font-size: 16.1px;

  line-height: 28px;
  letter-spacing: 0.22px;
}
@media screen and (max-width: 450px) {
  .head {
    font-size: 20px !important;
  }
  .para {
    font-size: 0.9rem !important;
  }
}
