.HeaderNavItemWrapper > * + * {
  margin-left: 1rem;
}

@media screen and (max-width: 1000px) {
  .HeaderNavItemWrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    height: 100vh;
    background: #fff;
    width: 60%;
    padding: 3rem 0rem;
    align-items: center;
    left: -100%;
    transition: 0.3s ease all;
  }

  .HeaderNavItemWrapper > * + * {
    margin-left: 0rem;
    margin-top: 2rem;
  }
}
