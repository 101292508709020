.ConfirmationPopUpCalender {
  background: #fff;
  padding: 2rem;
  padding-top: 3rem;
  width: 700px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  position: relative;
}
.ConfirmationPopUpCalenderWrapper {
  width: 100%;
  top: 0;
  left: 0;
  background: rgb(255, 255, 255);
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading {
  font-weight: 200;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1rem;
}
.Close {
  position: absolute;
  top: 20px;
  left: 30px;
  cursor: pointer;
}
.Ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.Li {
  margin-bottom: 0.3rem;
  font-size: 14px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
.label {
  font-size: 15px;
}
.input {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}
.textareawrapper {
  grid-column: span 2;
}
.textarea {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  resize: none;
  border: 1px solid #ddd;
  margin-top: 10px;
}
.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: span 2;
}
.button {
  padding: 0.5rem 1rem;
  border: 1px solid #326bff;
  background: #326bff;
  outline: none;
  margin-right: 5px;
  color: #fff;
  cursor: pointer;
}

.outline {
  background: transparent;
  color: #333;
}
@media screen and (max-width: 750px) {
  .ConfirmationPopUpCalender {
    width: 95%;
  }
}
