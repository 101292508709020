.Header {
  background: #fff;
  width: 100%;
  position: sticky;
  top: 0;
  transition: 0.3s ease all;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  z-index: 9;
}
.not_margin {
  background: transparent;
}
.not_margin a {
  color: #fff;
}
.not_margin h1 {
  color: #fff;
}
.bgwhite {
  background-color: #fff;
}
.bgwhite a {
  color: #000;
}
.bgwhite h1 {
  color: #000;
}
.bgwhite .fas {
  color: #000;
}
.HeaderContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.burgerIcon {
  display: none;
}
@media screen and (max-width: 1000px) {
  .burgerIcon {
    display: block;
  }
  .Header a {
    color: #000;
  }
}
