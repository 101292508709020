.InputWrapper {
  margin-bottom: 1rem;
}
.label {
  margin-bottom: 0.5rem;
  font-size: 18px;
}
@media screen and (max-width: 550px) {
  .InputWrapper {
    grid-column: span 2 !important;
  }
}
