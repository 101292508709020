.HomeCard {
  box-shadow: 1px 1px 3px 0 rgb(37 37 38 / 8%);
  background: #fff;
  border: 1px solid #e4e4e5;
  border-radius: 6px;
}
.top {
  display: flex;
  justify-content: center;

  padding: 1.5rem 0rem;
}
.topImg {
  height: 100px;
  width: 50%;
}
.presentation {
  margin-left: 1rem;
}
.body {
  display: flex;
  align-items: center;
  padding: 0rem 2rem;
  padding-bottom: 2rem;
}
.frontImg {
  width: 40px;
  height: 40px;
}
.heading {
  display: inline-block;
  margin-top: 0;
  font-weight: 600;

  color: #333;
  font-size: 16px;

  letter-spacing: 0.2px;
}

.para {
  width: 100%;
  max-width: 320px;
  margin-bottom: 10px;
  color: #868995;
  font-size: 12px;

  font-weight: 500;
}
.learn_more {
  color: #2e69ce;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}
@media screen and (max-width: 450px) {
  .body {
    flex-direction: column !important;
    align-items: flex-start;
  }
  .presentation {
    margin-left: 0rem;
    margin-top: 1rem;
  }
}
