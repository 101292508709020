body {
  background: transparent;
}
.LiveChat {
  position: fixed;
  bottom: 12px;
  right: 20px;
  display: flex;
  flex-direction: column;
}
/* .LiveChat */
.button {
  border: none;
  outline: none;
  margin-top: 0.5rem;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); */
  cursor: pointer;
  align-self: flex-end;
  box-shadow: 2px 2px 20px transparent;
}
.Img {
  pointer-events: none;
  width: 50px;
}
