.profile {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  align-items: center;
}
.Img {
  margin-right: 1rem;
}
.heading {
  font-size: 1.5rem;
}
.time {
  margin: 1rem 0rem;
}
.desc {
  line-height: 1.8rem;
}
.para {
  margin-left: 6px;
}
