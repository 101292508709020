.MainBody {
  margin-top: 2rem;
}
.Auth {
  margin-top: 0px;
}

.FrontPageLayout {
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 450px) {
  .MainBody {
    margin-top: 2rem;
  }
}
