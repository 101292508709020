.pricng_banner {
  background: #129ae9;
  height: 500px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}
.pricng_banner > h1 {
  font-size: 60px;
  font-weight: 800;
}
@media screen and (max-width: 1000px) {
  .pricng_banner > h1 {
    font-size: 35px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .pricng_banner {
    height: unset;
    padding-top: 7rem;
    padding-bottom: 3rem;
  }

  .pricng_banner > h1 {
    font-size: 28px;
  }
}
