.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 2rem;
}

.Image,
.pureImage {
  width: 100%;
}
.heading {
  font-size: 38px;
  color: #333;

  margin-bottom: 1rem;
}

.para {
  font-size: 1.2rem;
  color: #333;
  line-height: 34px;
  margin-bottom: 1rem;
}

.small {
  margin-top: 0.5rem;
  display: block;
}

@media screen and (max-width: 1200px) {
  .hero {
    grid-template-columns: 1fr;
  }
  .presentation {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .pureImage {
    width: 60%;
    margin: 0rem auto;
    margin-top: 5rem;
  }
  .full_width {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .Image {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .heading {
    font-size: 35px;
  }
  .para {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: 28px !important;
  }
  .para {
    font-size: 1rem !important;
  }
}
