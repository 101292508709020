.head,
.Subhead {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 1.4rem;
  margin-top: 3rem;
}
.list {
  display: block;
  margin-bottom: 2rem;
}
.Subhead {
  font-size: 17px;
}
.subList {
  padding-left: 2rem;
}
@media screen and (max-width: 650px) {
  .head {
    font-size: 18px;
  }
}
