.CollapseAbleLiveChat {
  /* background: #13215e; */
  width: 370px;
  color: #fff;
  padding: 1.5rem;
  height: 550px;
  overflow: auto;
  display: none;
  opacity: 0;
  transition: 0.3s ease all;
  transform: translateY(30px);
  border-radius: 14px;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  box-shadow: rgba(124, 124, 136, 0.25) 0px 50px 100px -20px,
    rgba(119, 80, 80, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.CloseIcon {
  position: absolute;
  top: 8vh;
  cursor: pointer;
  right: 20px;
  font-size: 20px;
}
.heading {
  font-size: 1.6rem;
  font-weight: 400;
  width: 90%;
}
.para {
  font-size: 17px;
}

.CollapseAbleLiveChat_600 {
  height: 100vh;
  padding-top: 8vh;
  width: 100%;
  bottom: 0 !important;
  left: 0;
  position: fixed;
  border-radius: 0px;
  opacity: 1 !important;
  transform: translateY(0px) !important;
}

.CollapseAbleLiveChat_400 {
  /* width: 95%; */
  align-self: flex-end;
}

.heading_400 {
  font-size: 1.8rem;
}
