.heading {
  margin-bottom: 2rem;
  color: #333;
  font-size: 38px;
}
.helpingMessage {
  display: block;
  text-align: center;
  margin: 2rem 0rem;
}
@media screen and (max-width: 600px) {
  .heading {
    font-size: 35px;
  }
}
