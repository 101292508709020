.PricingFaq {
  margin-bottom: 6rem;
}
.faq_grid {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  align-items: flex-start;
  grid-gap: 2rem;
  margin-top: 6rem;
}
.faq_grid .left_side h1 {
  font-weight: 700;
}
.faq_grid .left_side img {
  width: 100%;
}
.faq_grid .left_side p {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.mainheading {
  margin-bottom: 2.5rem;
  text-align: center;
  color: #333;
  font-size: 35px;
}
@media screen and (max-width: 450px) {
  .mainheading {
    font-size: 28px;
  }
}
@media screen and (max-width: 850px) {
  .faq_grid {
    grid-template-columns: 1fr;
  }
  .faq_grid > *:nth-child(1) {
    order: 2;
  }
  .faq_grid > *:nth-child(2) {
    order: 1;
  }
}
