.TrustedBrands {
  background-color: rgb(236, 236, 236);
  padding: 5rem 0rem;
}
.TrustedBrands .content_wrapper > h1 {
  color: #353a40;
  font-size: 1.5rem;
  line-height: 1.25em;
  text-align: center;
  font-weight: 700;
  margin-bottom: 3rem;
}

.logos_wrapper {
  display: grid;
  grid-template-columns: 1fr 0.9fr 2fr 1fr 1fr 2fr;
  justify-items: center;
  grid-gap: 2rem;
  align-items: center;
}
.logos_wrapper img {
  width: 100%;
}
.logos_wrapper img:nth-child(3) {
  width: 45%;
}
.logos_wrapper img:last-child {
  width: 85%;
}
@media screen and (max-width: 850px) {
  .logos_wrapper {
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
  }

  .logos_wrapper img {
    width: 100px;
  }
  .logos_wrapper img:nth-child(2) {
    width: 80px;
  }

  .logos_wrapper > * + * {
    margin-top: 1rem;
  }
}
