.PricingFaqRow {
  margin-bottom: 1rem;
}

.answer {
  font-size: 0.9rem;
  padding: 1.5rem;
  background: #fff;
  box-shadow: 0 5px 15px -5px rgb(0 0 0 / 10%);
  /* display: none; */
}
