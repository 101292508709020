.ReviewsWrapper {
  padding-bottom: 5rem;
  padding-top: 5rem;
  background-color: #f8f9f9;
}
.ReviewsWrapper .sliderWrapper {
  width: 90%;

  cursor: grab;
  margin: 0rem auto !important;
}
.ReviewsWrapper > h1 {
  font-size: 25px;
  position: relative;
  text-align: center;
  margin-bottom: 2rem;
  padding-bottom: 16px;
  /* border-bottom: 1px solid #326bff; */
}
.ReviewsWrapper > h1::after {
  bottom: 0px;
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 150px;
  background-color: #333;
}
.ReviewsWrapper ul {
  padding: 0px;
}
@media screen and (max-width: 500px) {
  .ReviewsWrapper > h1 {
    font-size: 22px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
