.PricingCardWrapper {
  margin: 6rem auto;
  padding: 6rem 0rem;
  margin-top: 0px;
  padding-top: 0px;
  background: #fff;
  margin-top: 30rem;
}
.dashboard {
  margin-top: 5rem;
  margin-left: 1.5rem;
}
.Content {
  margin: 0rem auto !important;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;

  grid-gap: 2rem;
}
@media screen and (max-width: 1000px) {
  .Content {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 700px) {
  .Content {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 500px) {
  .PricingCardWrapper {
    margin-top: 15rem;
  }
  .dashboard {
    margin-top: 5rem;
  }
}
