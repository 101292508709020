.LoginWrapper {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  position: absolute;
  top: 80px;
  font-size: 2rem;
  width: 85%;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 650px) {
  .logo {
    top: 20px;
  }
}
