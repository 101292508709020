.HomeContactBanner {
  background: #326bff;
  color: #fff;

  padding: 2rem 2rem;
}
.HomeContactBannerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 250px;
  align-items: center;
}
@media screen and (max-width: 850px) {
  .HomeContactBannerContent {
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .heading {
    font-size: 28px;
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: 22px;
  }
}
