.button {
  border-radius: 99px;
  font-size: 15px;

  padding: 1rem 1rem;
  min-width: 130px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.button.Outline {
  background: #fff;
  border: 1px solid #13225f;
  border-radius: 99px;
  color: #13225f;
  transition: 0.3s ease all;
  margin-left: 10px;
}
.button.Outline:hover {
  background: #13225f;
  color: #fff;
}
.button.Solid {
  background: #13225f;
  border: 1px solid #fff;
  border-radius: 99px;
  color: #fff;
}

@media screen and (max-width: 1000px) {
  .button.Outline {
    margin-left: 0px;
    margin-top: 10px !important;
  }
}
