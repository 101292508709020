.PricingCard {
  background-color: rgb(242, 244, 246);
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 5px 15px -5px rgb(0 0 0 / 10%);
  padding-bottom: 0rem;

  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  min-width: 100%;
  border-style: solid;
  border-width: 2px;
  border-color: #e9ecef;
  border-radius: 0.5em;
  background-color: #f2f4f6;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  /* padding-left: 2rem; */
}
.PricingCard:hover {
  border-color: #13225f;
}
.mainheading {
  display: inline-block;
  text-align: left;
  align-self: flex-start;
  color: #514d5c;
  font-size: 1.5rem;
  line-height: 1.25em;
  font-weight: 700;
}
.price {
  display: inline;
  margin-right: 0.125em;
  align-self: flex-start;
  color: #302357;
  font-size: 2rem;
  line-height: 1.25em;
  font-weight: 800;
  margin-bottom: 0px;
}
.price span {
  font-weight: 400;
  font-size: 20px;
  text-transform: lowercase;
}
.duration {
  display: block;
  align-self: flex-start;
  margin-bottom: 1rem;
  min-height: 40px;
  font-size: 16px;
  font-weight: 500;
  padding: 0rem 0rem;
  text-align: left;
}

.button {
  background: #13225f;
  padding: 0rem 1.5rem;

  width: 100%;
  margin: 0rem auto;
  height: 55px;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  margin-bottom: 1rem;
  border-radius: 10px;
  font-size: 1.125rem;
  line-height: 55px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
}
.freeTrailLink {
  font-size: 14px;
  color: #13225f;
}
