.sm-btn {
  height: 34px;
  min-width: 85px;
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #2d96d6;
  background-color: #fff;
  color: #2d96d6;
  cursor: pointer;
}
.burger-icon {
  display: none;
}
.d-flex,
.d-flex-align-center,
.d-flex-justify-center {
  display: flex;
}
.d-flex-align-center {
  align-items: center;
}
.d-flex-justify-center {
  justify-content: center;
}

.d-grid,
.grid-col-2,
.grid-col-3,
.grid-col-4 {
  display: grid;
}
.grid-col-2 {
  grid-template-columns: 1fr 1fr;
}
.grid-col-3 {
  grid-template-columns: 1fr 1fr 1fr;
}
.grid-col-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Sidebar {
  width: 110px;
  padding: 2rem 0rem;
  background-color: #0f1957;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Sidebar::-webkit-scrollbar {
  display: none;
}

.Sidebar nav {
  margin-top: 3rem;
  width: 100%;
  text-align: center;
}

.Sidebar nav > * + * {
  margin-top: 0.5rem;
}
.Sidebar nav > * {
  display: block;
  width: 100%;
}
.Sidebar nav a {
  padding: 0.4rem 0rem;
  display: block;
  position: relative;
  min-width: 100%;
}
.Sidebar nav li.active a svg path {
  fill: #2d96d7;
}
.Sidebar nav li a i {
  font-size: 30px;
  color: #6a7097;
}
.Sidebar nav li.active a i {
  color: #2d96d7;
}
.Sidebar nav li.active a::after {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 4px;
  content: "";
  background-color: #2d96d7;
}

.Sidebar .logout-area {
  display: flex;
  align-items: flex-end;
  margin-top: 4rem;
  width: 100%;
  justify-content: center;
  flex: 1;
}

/* body area */
.main-wrapper .body-area {
  flex: 1;
  height: 100vh;
  overflow: auto;
}
.main-wrapper .body-area .BodyHeader {
  border-bottom: 1px solid #e5e5e5;
  height: 70px;
  padding: 0rem 2rem;
  justify-content: space-between;
}
.bottom-header {
  position: fixed;
  bottom: 0;
  display: none !important;
  left: 0;
  width: 100%;
  z-index: 22;
  background: #0f1957;
  justify-content: space-between;
  padding: 0rem 1rem;
}
.bottom-header a {
  display: block;
}
.bottom-header li {
  padding: 1rem 1rem;
  position: relative;
}

.bottom-header li.active svg path {
  fill: #2d96d6;
}
.main-wrapper .body-area .BodyHeader .left-area {
  width: 400px;
}
.main-wrapper .body-area .BodyHeader .left-area input {
  flex: 1;
  margin-left: 10px;
  height: 35px;
  border: none;
  background-color: transparent;

  font-weight: 400;
  font-family: "Poppins", sans-serif;
  outline: none;
}
.main-wrapper .body-area .BodyHeader .left-area input::placeholder {
  color: #9ca2c9;
}
.main-wrapper .body-area .BodyHeader .right-area > * + * {
  margin-left: 1.7rem;
}
.main-wrapper .body-area .BodyHeader .right-area .sm-btn {
  background-color: transparent;
}
.main-wrapper .body-area .BodyHeader .right-area .profile-name-area > * + * {
  margin-left: 15px;
}
.main-wrapper .body-area .BodyHeader .right-area .profile-name-area p {
  font-size: 15px;
  color: #282d4a;
}

.main-wrapper .body-area .BodyHeader .right-area .icon-wrapper {
  cursor: pointer;
  display: flex;
}

@media screen and (max-width: 1050px) {
  .Sidebar {
    position: fixed;
    top: 0;
    left: 0;
    left: -100%;
    height: 100vh;
    transition: 0.5s ease all;
    overflow: auto;
    width: 30%;
    z-index: 222;
    padding-bottom: 8rem;
  }
  .burger-icon {
    display: block;
  }
  .Sidebar.active {
    left: 0;
  }
  .bottom-header {
    display: flex !important;
  }
  .main-wrapper .body-area .BodyHeader .right-area {
    position: absolute;
    top: 4rem;
    left: 0%;
    flex-direction: row-reverse;
    z-index: 22;
    width: 100%;
    background-color: #fff;
    padding: 1rem 2rem;
  }
  .main-wrapper .body-area .BodyHeader .right-area > * + * {
    margin-left: 0px;
  }
  .main-wrapper .body-area .BodyHeader .right-area .profile-name-area p {
    color: #000;
  }
  .main-wrapper .body-area .BodyHeader .right-area .sm-btn {
    color: #9953b7;
    border-color: #9953b7;
  }
  .main-wrapper .body-area .BodyHeader .right-area .icon-wrapper {
    flex: 1;
    justify-content: flex-start;
    margin-left: 1rem;
    display: flex;
  }
}
